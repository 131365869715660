/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onInitialClientRender = () => {
  //ナビゲーション高さ設定処理
  fnResizeNav()

  //リサイズ時
  window.onresize = function () {
    fnResizeNav()
  }

  //スクロール時
  window.onscroll = function () {
    fnResizeNav()
  }
}

function fnResizeNav() {
  let iHeightSmMin = 46 //ナビの高さ：SP版の狭い時
  let iHeightSmMax = 70 //ナビの高さ：SP版の広い時
  let iHeightPcMin = 80 //ナビの高さ：PC版の狭い時
  let iHeightPcMax = 124 //ナビの高さ：PC版の広い時

  let iPaddingSmMin = 0 //ナビの上パディング：SP版の狭い時
  let iPaddingSmMax = 10 //ナビの上パディング：SP版の広い時
  let iPaddingPcMin = 10 //ナビの上パディング：PC版の狭い時
  let iPaddingPcMax = 24 //ナビの上パディング：PC版の広い時

  //高さやパディングを求める
  let iH = 0
  let iP = 0
  let iScrollY = document.documentElement.scrollTop || document.body.scrollTop
  let iPer = 1 - iScrollY / iHeightPcMax
  iPer = Math.max(iPer, 0)
  if (window.matchMedia("(min-width:576px)").matches) {
    //----------
    //PCの場合
    //----------
    iH = iHeightPcMin + (iHeightPcMax - iHeightPcMin) * iPer
    iP = iPaddingPcMin + (iPaddingPcMax - iPaddingPcMin) * iPer
    let elements = document.getElementsByClassName("nav-pc")
    Array.prototype.forEach.call(elements, function (element) {
      element.classList.add("fixed-top")
    })
  } else {
    //----------
    //SPの場合
    //----------
    iH = iHeightSmMin + (iHeightSmMax - iHeightSmMin) * iPer
    iP = iPaddingSmMin + (iPaddingSmMax - iPaddingSmMin) * iPer
    // $('.nav-pc').removeClass('fixed-top');
    document.getElementsByClassName("nav-pc")[0].classList.remove("fixed-top")
  }

  //高さやパディングを設定
  let elements = document.getElementsByClassName("syouei-whitehedder")
  Array.prototype.forEach.call(elements, function (element) {
    element.style.height = iH + "px"
    element.style.paddingTop = iP + "px"
  })
}
